import Link from 'next/link';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/search/suggests/product/type';

export default function Product({ slug, icon, title }: Props) {
  return (
    <Card center className='h-[70px] justify-center rounded-lg bg-[#F7F8F9]' noBorder noShadow>
      <Link href={`/web/${slug}`} prefetch={false}>
        <CardContent center className='justify-start gap-2 p-[10px]'>
          <CardMedia
            src={icon.publicLink}
            alt={icon.title}
            width={50}
            height={50}
            className='rounded-[10px]'
          />
          <Text size={SIZE_ENUM.SM} className='line-clamp-1 text-[#5B5C60]'>
            {title}
          </Text>
        </CardContent>
      </Link>
    </Card>
  );
}
