'use client';
import { useEffect, useState } from 'react';

export function isSmallScreen(): boolean {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 600;
  }
  return false;
}

export function useClientSmallScreen() {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsSmall(isSmallScreen());
      };

      setIsSmall(isSmallScreen());
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return isSmall;
}
