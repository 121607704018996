import { TbShoppingCart } from 'react-icons/tb';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import AddToCartButton from '@/components/shared/components/add-to-cart-button';
import Props from '@/components/shared/components/cards/@shared/actions/type';
import PreviewButton from '@/components/shared/components/preview-button';

import { useAddonsStore } from '@/store/addons.store';

import { DATA_CY_ADD_TO_CARD_BUTTON } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import localization, { pageLevelLocalization } from '@/constant/localization';
const { product: productLocalization } = pageLevelLocalization;

export default function Actions({
  productId,
  demoId,
  slug,
  disabled,
  className,
  hasIcon = false,
  hasPreviewButton = true,
  previewButtonVariant = VARIANT_ENUM.GREY,
  isFree,
  isSubscription,
  subscriptionDefaultPriceIsOneYear,
}: Props) {
  const { addons, setAddons } = useAddonsStore();
  let subscriptionLifetime = addons.subscription_lifetime?.selected === true;
  let subscriptionOneYear = addons.subscription_one_year?.selected === true;
  return (
    <>
      {hasPreviewButton && (
        <PreviewButton
          className={className}
          variant={previewButtonVariant}
          hasIcon={hasIcon}
          slug={slug}
          demoId={demoId}
        />
      )}
      <AddToCartButton
        isFree={isFree}
        size={SIZE_ENUM.SM}
        className={cn(
          'text-bold h-[42px] flex-1 bg-[#FFAE11] p-0 text-[13px] text-white',
          className,
        )}
        disabled={disabled}
        data-cy={DATA_CY_ADD_TO_CARD_BUTTON}
        Icon={hasIcon && <TbShoppingCart color={ICON_COLOR.white} size={ICON_SIZE.sm} />}
        slug={slug}
        productId={productId}
        demoId={demoId}
      >
        {isSubscription
          ? subscriptionLifetime
            ? productLocalization.GetLifetimeSubscription
            : subscriptionOneYear
              ? productLocalization.GetOneYearSubscription
              : subscriptionDefaultPriceIsOneYear
                ? productLocalization.GetOneYearSubscription
                : productLocalization.GetLifetimeSubscription
          : localization.addToCart}
      </AddToCartButton>
    </>
  );
}
