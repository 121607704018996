import Container from '@/components/@base/container';
import { useGetSearchSuggestion } from '@/components/layout/services/hooks';
import Field from '@/components/shared/components/search/field';
import PreviousSearch from '@/components/shared/components/search/previous-search';
import Result from '@/components/shared/components/search/result';
import Suggests from '@/components/shared/components/search/suggests';
import { useGetSearch } from '@/components/shared/services/hooks';

import { useSearchStore } from '@/store/search.store';

export default function SearchModal() {
  const { data, isPending } = useGetSearchSuggestion();
  const { data: searchResult } = useGetSearch({});
  const { params } = useSearchStore();

  return (
    <Container>
      <Container className='px-5 pt-5'>
        <Field />
      </Container>
      {searchResult && params ? (
        <Result demos={searchResult.demos} products={searchResult.products} />
      ) : (
        <>
          <Suggests data={data?.suggestions || []} isLoading={isPending} />
          <PreviousSearch data={data?.previousSearch || []} isLoading={isPending} />
        </>
      )}
    </Container>
  );
}
