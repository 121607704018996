'use client';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';

import { eventTracker } from '@/lib/service-workers';

import Container from '@/components/@base/container';
import { CONTAINER_TAG_TYPE } from '@/components/@base/container/type';
import { Input } from '@/components/@base/input';
import { useGetProfile } from '@/components/layout/services/hooks';
import { useGetSearch } from '@/components/shared/services/hooks';

import { useSearchStore } from '@/store/search.store';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;

export default function SearchField() {
  const [value, setValue] = useState('');
  const { refetch } = useGetSearch({ params: { find: value, with_demo: true }, enabled: true });
  const { setParams } = useSearchStore();
  const { profile } = useGetProfile();

  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedRefetch = useCallback(
    debounce((value) => {
      refetch();
      setParams(value);
      eventTracker(profile?.mobile, 'search_query', new Date().toISOString(), {
        find: value,
      });
    }, 1000),
    [refetch, setParams],
  );

  useEffect(() => {
    inputRef?.current?.focus();
    if (value) {
      debouncedRefetch(value);
    }
    return () => {
      debouncedRefetch.cancel();
    };
  }, [value, debouncedRefetch]);

  useEffect(() => {
    if (value === '') {
      setParams('');
    }
  }, [value, setParams]);
  return (
    <Input
      placeholder={layoutLocalization.forExampleWoodmart}
      containerClassName='border-[#E5E8EB] h-[68px] border shadow-[0px_2px_8px_0px_rgba(73,75,84,0.05)] text-base rounded-lg w-full max-w-[640px]'
      className='border-none'
      value={value}
      ref={inputRef}
      onChange={(e) => setValue(e.target.value)}
      PlaceholderIcon={() => (
        <Container type={CONTAINER_TAG_TYPE.SPAN} className='pr-[22px]'>
          <CiSearch size={ICON_SIZE.lg} color={ICON_COLOR.gray} strokeWidth={0.5} />
        </Container>
      )}
    />
  );
}
