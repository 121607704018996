import Skeleton from 'react-loading-skeleton';

import Carousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';
import IconTextCombo from '@/components/shared/components/icon-text-combo';
import Product from '@/components/shared/components/search/suggests/product';
import Props from '@/components/shared/components/search/suggests/type';

import useCommonModalStore from '@/store/common-modal-store';

import { DATA_CY_SEARCH_POP_UP } from '@/constant/cypress';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;
export default function Suggests({ data, isLoading }: Props) {
  const { setShow } = useCommonModalStore();

  const handleCloseModal = () => {
    setShow(false);
  };
  return (
    <Container data-cy={DATA_CY_SEARCH_POP_UP} className='w-full pb-8 pr-5 pt-5 [&>*]:w-full'>
      <IconTextCombo
        Icon={
          <XImage
            src={imagesList.icons.fireIcon}
            alt={localization.zhaket}
            width={22}
            height={18}
          />
        }
        title={layoutLocalization.zhkSuggestions}
      />
      <Container className='pt-3'>
        <Carousel spaceBetween={10} slidesPerView='auto'>
          {isLoading ? (
            <>
              <CarouselItem className='!w-[234px]'>
                <Skeleton width={234} height={70} className='rounded-lg' />
              </CarouselItem>
              <CarouselItem className='!w-[234px]'>
                <Skeleton width={234} height={70} className='rounded-lg' />
              </CarouselItem>
            </>
          ) : (
            data?.map((product) => {
              return (
                <CarouselItem
                  key={product.id}
                  className='!w-[234px]'
                  onClick={handleCloseModal}
                >
                  <Product {...product} />
                </CarouselItem>
              );
            })
          )}
        </Carousel>
      </Container>
    </Container>
  );
}
