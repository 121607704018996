import ImageBanner from '@/components/shared/components/cards/banner/image-banner';
import TextBanner from '@/components/shared/components/cards/banner/text-banner';
import { BANNER_TYPE, Props } from '@/components/shared/components/cards/banner/type';

export default function Banner({ type, width, height, target, rel, ...props }: Props) {
  if (type === BANNER_TYPE.IMAGE) {
    return <ImageBanner {...props} width={width} height={height} target={target} rel={rel} />;
  }
  return <TextBanner {...props} />;
}
