import { FiShoppingCart } from 'react-icons/fi';

import Container from '@/components/@base/container';
import AddToCartButton from '@/components/shared/components/add-to-cart-button';
import { Props } from '@/components/shared/components/cards/bundle/add-to-cart/type';

import ICON_SIZE from '@/constant/icon-size-color';

export default function AddToCart({ slug, productId }: Props) {
  return (
    <AddToCartButton
      slug={slug}
      productId={productId}
      className='absolute -top-[15%] flex h-14 w-14 items-center justify-center rounded-[10px] bg-[linear-gradient(180deg,_#3B4153_0%,#000000_100%)] p-0'
    >
      <Container
        className='h-[59px] w-[59px] rounded-[10px] bg-[#1E222E] transition duration-300 hover:bg-[#EB8800] md:h-[69px] md:w-[69px]'
        center
      >
        <FiShoppingCart
          size={ICON_SIZE.md}
          className='text-[#FFC87B] transition duration-300 group-hover:text-white'
        />
      </Container>
    </AddToCartButton>
  );
}
