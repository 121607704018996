import Skeleton from 'react-loading-skeleton';

import Card, { CardContent } from '@/components/@base/card';

export default function Loading() {
  return (
    <Card
      noShadow
      noBorder
      center
      className='flex-row items-center justify-start gap-3 rounded-none border-b-[1px] border-[#F4F5F6] py-[14px]'
    >
      <Skeleton width={35} height={35} />

      <CardContent className='p-0'>
        <Skeleton width={200} />
      </CardContent>
    </Card>
  );
}
