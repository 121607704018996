import { FaStar } from 'react-icons/fa';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/icon/star/type';
import { toFixedWithoutZeros } from '@/components/shared/helpers';

import ICON_SIZE from '@/constant/icon-size-color';
import localization from '@/constant/localization';

export default function Star({
  value = 0,
  textSize = SIZE_ENUM.SM,
  textClassName,
  iconSize = ICON_SIZE.sm,
  showRateCount = false,
  rateCount,
  reverse,
}: Props) {
  return value > 0 ? (
    <Container center className='gap-1'>
      {reverse && <FaStar color='#FFC87B' size={iconSize} />}
      <Text size={textSize} className={cn('pt-1 text-[#878F9B]', textClassName)}>
        {toFixedWithoutZeros(value, 1)}
      </Text>
      {!reverse && <FaStar color='#FFC87B' size={iconSize} />}
      {showRateCount && (
        <Text className='text-[#76767C]'>
          {localization.outOf} {rateCount} {localization.vote}
        </Text>
      )}
    </Container>
  ) : null;
}
