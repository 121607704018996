import { create } from 'zustand';

interface SearchStore {
  params: string;
  setParams: (val: string) => void;
}

export const useSearchStore = create<SearchStore>((set) => ({
  params: '',
  setParams: (params) => set({ params }),
}));
