import Link from 'next/link';

import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Item from '@/components/shared/components/search/result/item';
import Loading from '@/components/shared/components/search/result/item/loading';
import Props from '@/components/shared/components/search/result/type';
import { useGetSearch } from '@/components/shared/services/hooks';

import useCommonModalStore from '@/store/common-modal-store';
import { useSearchStore } from '@/store/search.store';

import localization from '@/constant/localization';

export default function Result({ demos, products }: Props) {
  const { params } = useSearchStore();
  const { data, isRefetching } = useGetSearch({});
  const { setShow } = useCommonModalStore();

  const handleCloseModal = () => {
    setShow(false);
  };

  if (!data) {
    return null;
  }
  const noResults = !isRefetching && !demos?.length && !products?.length;

  return (
    <Container center className='min-h-[335px] flex-col items-start justify-between p-5'>
      {isRefetching ? (
        <Loading />
      ) : (
        <>
          {noResults ? (
            <Container center className='h-[200px] w-full p-5 text-[#788DAB]'>
              <Text>{localization.notFoundAnyResult}</Text>
            </Container>
          ) : (
            <>
              {products.length > 0 && (
                <Container>
                  <Text bold className='text-[#424244]'>
                    {localization.products}
                  </Text>
                  {products
                    ?.slice(0, demos?.length ? 2 : 3)
                    .map((item) => (
                      <Item
                        title={item.title}
                        slug={item.slug}
                        icon={item.icon}
                        key={item.id}
                        demoId={null}
                      />
                    ))}
                </Container>
              )}

              {demos?.length > 0 && (
                <Container>
                  <Text bold className='pt-5 text-[#424244]'>
                    {localization.themeDemos}
                  </Text>
                  {demos
                    ?.slice(0, products?.length ? 2 : 3)
                    .map((item) => (
                      <Item
                        title={item.title}
                        slug={item.slug}
                        icon={item.icon}
                        key={item.id}
                        demoId={item.demoId ?? null}
                      />
                    ))}
                </Container>
              )}
            </>
          )}
        </>
      )}
      <Button className='mt-7 h-[46px] w-full bg-[#F7F8F9] py-3'>
        <Link
          href={`/search?find=${params}`}
          onClick={handleCloseModal}
          className='flex'
          prefetch={false}
        >
          <Text className='text-[#878F9B]' bold>
            {localization.seeAllResult}
          </Text>
          <Text bold className='pr-1 text-[#424244]'>
            {params}
          </Text>
        </Link>
      </Button>
    </Container>
  );
}
