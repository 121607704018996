'use client';

import Link from 'next/link';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Tooltip from '@/components/@base/tooltip';
import XImage from '@/components/@base/x-image';
import Props from '@/components/shared/components/icon/sequential/icon/type';

export default function Icon({
  icon,
  defaultIconUrl = '',
  title,
  iconClassName,
  size,
  style,
  background,
  slug,
  ...props
}: Props) {
  const iconUrl = icon && icon.publicLink ? icon.publicLink : defaultIconUrl;
  return (
    <Container
      center
      className={cn('rounded-full border-2 border-solid', iconClassName)}
      style={{ ...style, background }}
      {...props}
      data-tooltip-id={`product-medal-tooltip-${slug}`}
      data-tooltip-content={title || ''}
    >
      {slug ? (
        <Link href={`/web/${slug}`} prefetch={false}>
          <XImage
            src={iconUrl}
            alt={title}
            width={size}
            height={size}
            className='rounded-full'
          />
        </Link>
      ) : (
        <XImage
          src={iconUrl}
          alt={title}
          width={size}
          height={size}
          className='rounded-full'
        />
      )}
      {title && <Tooltip id={`product-medal-tooltip-${slug}`} className='!shadow-[0px_1px_3px_0px_rgba(0,0,0,0.04)]'/>}
    </Container>
  );
}
