import { RiShoppingCart2Line } from 'react-icons/ri';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import Props from '@/components/shared/components/sale-amount/type';

import localization from '@/constant/localization';

export default function SaleAmount({
  value = 0,
  showLabel = false,
  Icon = <RiShoppingCart2Line color='#878F9B' />,
  reverse,
}: Props) {
  return (
    <Container center className='gap-1'>
      {reverse && Icon}
      <Text type={TEXT_TYPE.SPAN} size={SIZE_ENUM.SM} className='pt-1 text-[#878F9B]'>
        {value || 0}
      </Text>
      {!reverse && Icon}
      {showLabel && <Text className='text-[#76767C]'>{localization.sale}</Text>}
    </Container>
  );
}
