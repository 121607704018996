import Link from 'next/link';
import React, { useState } from 'react';
import { RiShoppingCart2Line } from 'react-icons/ri';

import cn from '@/lib/clsxm';
import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardAction, CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { MARKETS } from '@/components/app/product/components/@shared/type';
import { useGetProfile } from '@/components/layout/services/hooks';
import Actions from '@/components/shared/components/cards/@shared/actions';
import Loading from '@/components/shared/components/cards/product/loading';
import Props from '@/components/shared/components/cards/product/type';
import Star from '@/components/shared/components/icon/star';
import PriceView from '@/components/shared/components/price-view';
import SaleAmount from '@/components/shared/components/sale-amount';

import { DATA_CY_PRODUCT_CONTENT_CARD } from '@/constant/cypress';
import ICON_SIZE from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';

export default function ProductCard({ data, className, isLoading = false, ...props }: Props) {
  const {
    title,
    id,
    cover,
    slug,
    price,
    lastPrice,
    discount,
    rate,
    saleAmount,
    sellable,
    saleEndedAt,
    store,
    categories,
    isOnSale,
  } = data;
  const finalDiscount = isOnSale ? discount : 0;

  const [isHovered, setIsHovered] = useState(false);
  const { profile } = useGetProfile();
  const eventHandler = () => {
    eventTracker(profile?.mobile, 'product_click', new Date().toISOString(), {
      url: window.location?.href,
      category: categories?.map((item) => ({
        $oid: item?.id,
      })),
      vendor: store?.id,
      price: price,
    });
    handleGA4Event('select_item', [
      {
        item_id: id,
        item_name: title,
        price: price,
        index: 0,
        quantity: 1,
        affiliation: store?.slug,
        discount: discount,
      },
    ]);
  };
  return isLoading || !data ? (
    <Loading />
  ) : (
    <Card
      noBorder
      noShadow
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cn(
        'group relative aspect-[0.74] h-auto w-full max-w-[400px] rounded-md shadow-[0px_5px_25px_0px_rgba(126,137,155,0.11)] md:max-h-[410px] md:max-w-[304px]',
        className,
      )}
      onClick={eventHandler}
      {...props}
    >
      <Link href={`/web/${slug}`} prefetch={false}>
        <CardMedia
          src={cover?.publicLink || imagesList.images.productDefaultCover}
          className='w-full'
          alt={cover?.title}
          width={272}
          height={272}
        />
      </Link>
      <CardContent
        data-cy={DATA_CY_PRODUCT_CONTENT_CARD}
        className={cn(
          'product-content product-card-content absolute bottom-0 w-full bg-white p-[20px] transition-all duration-300',
          isHovered ? 'bottom-0' : 'md:bottom-[-62px]',
        )}
      >
        <Text
          size={SIZE_ENUM.MD}
          bold
          className='truncate-2 min-h-[60px] pb-2 text-[#424244] group-hover:text-[#ff9606]'
          // type='h3'
        >
          <Link href={`/web/${slug}`} prefetch={false}>
            {title}
          </Link>
        </Text>
        <Container center className='w-full items-end justify-between pb-4'>
          <PriceView
            sellable={sellable}
            price={price}
            lastPrice={lastPrice}
            discount={finalDiscount}
            saleEndedAt={saleEndedAt}
            showDiscountLabel
            showDiscountWithoutExpiration={data.market === MARKETS.BUNDLE}
            className='flex items-center gap-2'
          />
          <Container center className='gap-3.5'>
            <Star value={rate?.rateAverage} />
            <SaleAmount
              value={saleAmount}
              Icon={<RiShoppingCart2Line color='#878F9B' size={ICON_SIZE.sm} />}
            />
          </Container>
        </Container>
        <CardAction center className='w-full gap-2 p-0'>
          <Actions
            isFree={price === 0}
            disabled={!sellable}
            slug={slug}
            productId={id}
            hasPreviewButton={data.market !== MARKETS.BUNDLE}
          />
        </CardAction>
      </CardContent>
    </Card>
  );
}
