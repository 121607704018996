import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Icon from '@/components/shared/components/icon/sequential/icon';
import Props from '@/components/shared/components/icon/sequential/type';

export default function Sequential({
  icons,
  wrapperClassName,
  iconClassName,
  size,
  shiftAmount,
  defaultIconUrl,
  ...props
}: Props) {
  return (
    <Container center className='w-full justify-end'>
      <Container className={cn('relative', wrapperClassName)}>
        {icons?.map((item, index) => (
          <Icon
            key={index}
            icon={item.icon}
            title={item.title}
            slug={item?.slug}
            iconClassName={iconClassName}
            background={item?.backgroundColor}
            size={size}
            style={{ left: `${shiftAmount * index + 1}px` }}
            {...props}
            defaultIconUrl={defaultIconUrl}
          />
        ))}
      </Container>
    </Container>
  );
}
