import Link from 'next/link';
import React from 'react';

import cn from '@/lib/clsxm';
import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { useGetProfile } from '@/components/layout/services/hooks';
import Props from '@/components/shared/components/cards/iconic-product/type';
import Star from '@/components/shared/components/icon/star';
import SaleAmount from '@/components/shared/components/sale-amount';

export default function IconicProductCard({ data, noBorder, className, ...props }: Props) {
  const { icon, title, saleAmount, rate, id, discount, slug, categories, store, price } = data;
  const { profile } = useGetProfile();
  const eventHandler = () => {
    eventTracker(profile?.mobile, 'product_click', new Date().toISOString(), {
      url: window.location?.href,
      category: categories?.map((item) => ({
        $oid: item?.id,
      })),
      vendor: store?.id,
      price: price,
    });
    handleGA4Event('select_item', [
      {
        item_id: id,
        item_name: title,
        price: price,
        index: 0,
        quantity: 1,
        affiliation: store?.slug,
        discount: discount,
      },
    ]);
  };
  return (
    <Card
      className={cn('flex-row items-center justify-start bg-white p-2', className)}
      noShadow
      noBorder={noBorder}
      onClick={eventHandler}
      {...props}
    >
      <Link href={`/web/${slug}`} className='shrink-0' prefetch={false}>
        <CardMedia
          src={icon?.publicLink}
          width={68}
          height={68}
          alt={icon?.title}
          className='h-[68px] w-[68px] rounded-lg'
        />
      </Link>

      <CardContent className='flex flex-col overflow-hidden p-3'>
        <Text
          size={SIZE_ENUM.SM}
          bold
          className='line-clamp-1 text-[#5B5C60] hover:text-[#ff9606]'
        >
          <Link href={`/web/${slug}`} prefetch={false}>
            {title}
          </Link>
        </Text>
        <Container className='flex items-center gap-3'>
          <Star value={rate?.rateAverage} reverse />
          <SaleAmount value={saleAmount} reverse />
        </Container>
      </CardContent>
    </Card>
  );
}
