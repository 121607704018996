import Link from 'next/link';

import cn from '@/lib/clsxm';

import Card, { CardMedia } from '@/components/@base/card';
import { Props } from '@/components/shared/components/cards/banner/image-banner/type';

export default function ImageBanner({
  link,
  image,
  title,
  width,
  height,
  target,
  rel,
  ...props
}: Props) {
  return (
    <Card
      noShadow
      noBorder
      className={cn('shadow-[0px_5px_25px_0px_rgba(126,137,155,0.05)]')}
      {...props}
    >
      <Link href={link} target={target} rel={rel} prefetch={false}>
        {image && title && (
          <CardMedia
            src={image}
            alt={title}
            width={width}
            height={height}
            className='w-full'
          />
        )}
      </Link>
    </Card>
  );
}
