import Link from 'next/link';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/search/previous-search/item/type';

export default function Item({ title }: Props) {
  return (
    <Container center className='h-10 min-w-fit rounded-md bg-[#ECF1FC] px-[25px] py-[9px]'>
      <Link href={`/search?find=${title}`} prefetch={false}>
        <Text size={SIZE_ENUM.SM} className='text-[#788DAB]'>
          {title}
        </Text>
      </Link>
    </Container>
  );
}
