import Link from 'next/link';

import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import { useGetProfile } from '@/components/layout/services/hooks';
import Props from '@/components/shared/components/cards/cubic-product/type';

import imagesList from '@/constant/images-list';

export default function CubicProduct({
  icon,
  title,
  slug,
  bestSellerBadge,
  discount,
  isOnSale,
  saleEndedAt = Date.now(),
  categories,
  store,
  price,
  id,
}: Props) {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const discountIsValid = saleEndedAt > currentTimeInSeconds && isOnSale && discount > 0;
  const { profile } = useGetProfile();
  const eventHandler = () => {
    eventTracker(profile?.mobile, 'product_click', new Date().toISOString(), {
      url: window.location?.href,
      category: categories?.map((item) => ({
        $oid: item?.id,
      })),
      vendor: store?.id,
      price: price,
    });
    handleGA4Event('select_item', [
      {
        item_id: id,
        item_name: title,
        price: price,
        index: 0,
        quantity: 1,
        affiliation: store?.slug,
        discount: discount,
      },
    ]);
  };
  return (
    <Card
      className='relative h-[208px] w-[195px] rounded-lg border-solid border-[#F4F4F4] bg-white px-4 py-7'
      onClick={eventHandler}
    >
      <Link href={`/web/${slug}`} prefetch={false}>
        <CardContent center className='flex-col gap-5 p-0'>
          {!!bestSellerBadge && (
            <XImage
              src={imagesList.icons.hotProductIcon}
              alt='hot'
              className='absolute left-[17px] top-[17px]'
              width={13}
              height={18}
            />
          )}
          {discountIsValid && (
            <Container
              center
              className='absolute left-0 top-[15px] h-[22px] w-[41px] rounded-r-[4px] bg-[linear-gradient(247.65deg,_#FFC107_-35.57%,_#FF9737_100%)] text-center text-[13px] font-bold leading-[18px] text-[#FFFFFF]'
            >
              {discount}&#37;
            </Container>
          )}
          <CardMedia src={icon?.publicLink} alt={icon?.title} width={80} height={80} />
          <Text
            center
            size={SIZE_ENUM.SM}
            className='line-clamp-2 text-[#5B5C60] hover:text-[#ff9606]'
          >
            {title}
          </Text>
        </CardContent>
      </Link>
    </Card>
  );
}
