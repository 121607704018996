import React from 'react';

import { getQueryData } from '@/lib/get-query-client';

import { GET_STORY_LIST } from '@/constant/query-key';
import FaqModel from '@/models/faq.model';
import StoryModel from '@/models/story.model';

export function getStoriesListFromCache(): StoryModel[] | undefined {
  return getQueryData<StoryModel[]>([GET_STORY_LIST]);
}

export function formatDate(
  timestamp: number,
  locale: string = 'fa-IR',
  options: Intl.DateTimeFormatOptions = {},
) {
  if (!timestamp) {
    return '';
  }
  let timeInMS = timestamp;
  if (timestamp.toString().length <= 10) {
    timeInMS = timestamp * 1000;
  }

  return new Intl.DateTimeFormat(locale, options).format(timeInMS);
}

export function generateDefaultFaq(nameVar: string): FaqModel[] {
  return [
    {
      question: `چگونه از سایت ژاکت، ${nameVar} را خریداری کنم؟`,
      answer: `شما میتوانید با رفتن به صفحه ${nameVar}، محصول وردپرسی مورد نظر خود را انتخاب کرده و توضیحات تکمیلی ${nameVar} را مشاهده کنید و در صورت نیاز با کلیک بر روی دکمه افزودن به سبد خرید، ${nameVar} را برای سایت خود خریداری کنید`,
    },
    {
      question: `ویژگی‌های مهم ${nameVar} چیست؟`,
      answer: `یکی از مهم‌ترین ویژگی‌های ${nameVar} ژاکت، اصالت و اورجینال بودن آن است. ویژگی بعدی محصولات ${nameVar} پشتیبانی ۶ ماهه ژاکت و توسعه‌دهنده محصول وردپرسی است که شما میتوانید تمامی سوالات خود را از توسعه‌دهندگان بپرسید.`,
    },
    {
      question: `چگونه میتوانیم ${nameVar} خریداری‌شده را نصب کنیم؟`,
      answer: `شما میتوانید فایل ${nameVar} را از طریق پنل کاربری خود دانلود کنید، سپس از دو روش نصب از طریق پیشخوان وردپرس و یا استفاده از بسته‌نصبی آسان، ${nameVar} خود را به راحتی نصب کنید`,
    },
    {
      question: `چرا باید ${nameVar} را از ژاکت خریداری کنم؟`,
      answer: `تمامی محصولات ${nameVar} ژاکت پس از بررسی توسط متخصص‌های ژاکت و همچنین تایید اصالت کالا از وبسایت‌های معتبر وردپرس، به فروش میرسند. همچنین پس از خریداری ${nameVar} توسعه‌دهندگان موظف هستند که به تمامی سوالات شما در رابطه با ${nameVar} در کوتاه‌ترین زمان پاسخ دهند. همچنین تمامی ${nameVar} پرطرفدار در بازه‌های زمانی کوتاه بروزرسانی می‌شوند و شما میتوانید به آخرین نسخه ${nameVar} دسترسی داشته باشید`,
    },
  ];
}

export const loadingGenerator = (
  count: number = 1,
  LoadingComponent: () => React.ReactNode,
) => {
  return Array.from({ length: count }, (_, index) => <LoadingComponent key={index} />);
};

export const toCamelCase = (val: string) => {
  return val.replace(/-./g, (m) => m[1].toUpperCase());
};

export const toFixedWithoutZeros = (num, precision) => `${1 * num.toFixed(precision)}`;

export const isJSON = (value: string): boolean => {
  if (
    value?.startsWith('{') ||
    value?.startsWith('[') ||
    value?.startsWith('"') ||
    value === 'null' ||
    value === 'true' ||
    value === 'false' ||
    !isNaN(Number(value))
  ) {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

export const readParams = (params: URLSearchParams) => {
  const filtersFromParams = {};
  params.forEach((value, key) => {
    if (value) {
      filtersFromParams[key] = isJSON(value) ? JSON.parse(value) : value;
    }
  });
  return filtersFromParams;
};
