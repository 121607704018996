import Link from 'next/link';
import React from 'react';

import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import { useGetProfile } from '@/components/layout/services/hooks';
import AddToCart from '@/components/shared/components/cards/bundle/add-to-cart';
import Props from '@/components/shared/components/cards/bundle/type';
import { SequentialIcons } from '@/components/shared/components/icon';
import PriceView from '@/components/shared/components/price-view';

import imagesList from '@/constant/images-list';
import { BundleTypes } from '@/models/bundle-item.model';

export default function BundleCard({
  cover,
  title,
  details,
  shortDescription,
  price,
  lastPrice,
  bundleItems,
  slug,
  id,
  categories,
  store,
  discount,
}: Props) {
  const pluginNumbers = bundleItems?.reduce((total, item) => {
    if (item.type === BundleTypes.PLUGIN) {
      total += 1;
    }
    return total;
  }, 0);

  const themeNumbers = bundleItems?.reduce((total, item) => {
    if (item.type === BundleTypes.THEME) {
      total += 1;
    }
    return total;
  }, 0);
  const pluginDisplay = pluginNumbers > 0 ? `${pluginNumbers} افزونه` : '';
  const themeDisplay = themeNumbers > 0 ? `${themeNumbers} قالب` : '';
  const { profile } = useGetProfile();
  const eventHandler = () => {
    eventTracker(profile?.mobile, 'product_click', new Date().toISOString(), {
      url: window.location?.href,
      category: categories?.map((item) => ({ $oid: item })),
      vendor: store?.id,
      price: price,
    });
    handleGA4Event('select_item', [
      {
        item_id: id,
        item_name: title,
        price: price,
        index: 0,
        quantity: 1,
        affiliation: store?.slug,
        discount: discount,
      },
    ]);
  };
  return (
    <Card
      noBorder
      noShadow
      className='group relative h-[393px] cursor-grab md:h-[365px]'
      onClick={eventHandler}
    >
      <figure>
        <CardContent className='p-0'>
          <CardMedia
            src={cover.publicLink || imagesList.images.productDefaultCover}
            alt={title}
            className='w-full rounded-b-full'
            width={272}
            height={272}
          />
          <Container className='absolute -bottom-2 w-full bg-[#1B1E27] p-[30px] pt-[40px]'>
            <Container className='absolute -top-[100%] right-0 h-[100%] w-full bg-[linear-gradient(0,rgba(27,30,39,1)_0%,rgba(27,30,39,0)_94.01%)]' />
            <AddToCart slug={slug} productId={id} />
            <Container className='-top-[15%] right-[100px] md:absolute'>
              <Link href={`/web/${slug}`} prefetch={false}>
                <Text
                  size={SIZE_ENUM.MD}
                  bold
                  className='line-clamp-1 text-[#EDEDED] group-hover:text-[#ff9606] md:text-lg'
                >
                  {title}
                </Text>
              </Link>
              <Text type={TEXT_TYPE.SPAN} className='truncate-2 text-[#808593]' bold>
                {pluginDisplay} {pluginDisplay && themeDisplay && ' + '}
                {themeDisplay}
              </Text>
            </Container>
            <figcaption className='md:pb-4 md:pt-5'>
              <Text className='hidden text-[#C1C3C8] md:line-clamp-2' medium>
                {shortDescription}
              </Text>
            </figcaption>
            <Container className='mt-2 flex flex-col gap-3 md:flex-row md:items-center md:gap-[40%]'>
              <PriceView
                showDiscountWithoutExpiration
                showDiscountLabel
                isDark
                price={price}
                lastPrice={lastPrice}
                discount={discount}
                className='flex items-center gap-2'
              />
              <SequentialIcons
                icons={bundleItems}
                size={24}
                iconClassName='absolute h-10 w-10 rounded-full border-2 border-solid border-[#3D3E49] bg-[#242732]'
                wrapperClassName='relative h-10'
                shiftAmount={23}
              />
            </Container>
          </Container>
        </CardContent>
      </figure>
    </Card>
  );
}
