import Link from 'next/link';

import Card, { CardContent } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Props } from '@/components/shared/components/cards/banner/text-banner/type';

export default function TextBanner({ link, title, subTitle, buttonText }: Props) {
  return (
    <Card>
      <CardContent>
        <Container>
          <Link href={link} prefetch={false}>{title}</Link>
          <Link href={link} prefetch={false}>{subTitle}</Link>
        </Container>
        <Link href={link} prefetch={false}>{buttonText}</Link>
      </CardContent>
    </Card>
  );
}
