export enum PRODUCT_TABS {
  CONTENT = 'content',
  COMMENTS = 'comments',
  SUPPORT = 'support',
  DEVELOPER_DISCOUNT_CODES = 'developer-discount-codes',
}

export enum MARKETS {
  BUNDLE = 'Bundle',
  PRODUCT = 'Web',
}

export enum SOCIAL_MEDIA_TYPE {
  LINKEDIN = 'linkedin',
  WHATSAPP = 'whatsapp',
  TWITTER = 'twitter',
  TELEGRAM = 'telegram',
}
