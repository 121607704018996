import Skeleton from 'react-loading-skeleton';

import Carousel, { CarouselItem } from '@/components/@base/carousel';
import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';
import IconTextCombo from '@/components/shared/components/icon-text-combo';
import Item from '@/components/shared/components/search/previous-search/item';
import Props from '@/components/shared/components/search/previous-search/type';

import useCommonModalStore from '@/store/common-modal-store';

import { DATA_CY_SEARCH_POP_UP } from '@/constant/cypress';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;

export default function PreviousSearch({ data, isLoading }: Props) {
  const { setShow } = useCommonModalStore();

  const handleCloseModal = () => {
    setShow(false);
  };
  return (
    <Container data-cy={DATA_CY_SEARCH_POP_UP} className='pb-8'>
      <Container className='px-5'>
        <IconTextCombo
          Icon={
            <XImage
              src={imagesList.icons.fireIcon}
              alt={localization.zhaket}
              width={22}
              height={18}
            />
          }
          title={layoutLocalization.favouriteSearch}
          wrapperClassName='pt-4 border-t border-t-[#F4F5F6]'
        />
      </Container>
      <Container className='pr-5 pt-1'>
        <Carousel spaceBetween={10} slidesPerView='auto'>
          {isLoading ? (
            <>
              <CarouselItem className='!w-[140px]'>
                <Skeleton height={40} className='rounded-lg' />
              </CarouselItem>
              <CarouselItem className='!w-[170px]'>
                <Skeleton height={40} className='rounded-lg' />
              </CarouselItem>
              <CarouselItem className='!w-[130px]'>
                <Skeleton height={40} className='rounded-lg' />
              </CarouselItem>
              <CarouselItem className='!w-[150px]'>
                <Skeleton height={40} className='rounded-lg' />
              </CarouselItem>
            </>
          ) : (
            data?.map((item, index) => {
              return (
                <CarouselItem key={index} className='!w-fit' onClick={handleCloseModal}>
                  <Item title={item} />
                </CarouselItem>
              );
            })
          )}
        </Carousel>
      </Container>
    </Container>
  );
}
