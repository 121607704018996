import Skeleton from 'react-loading-skeleton';

import cn from '@/lib/clsxm';

import Card from '@/components/@base/card';
import Container from '@/components/@base/container';

export default function Loading() {
  return (
    <Card
      noBorder
      noShadow
      className={cn(
        'group relative aspect-[0.74] h-auto w-full max-w-[400px] rounded-md shadow-[0px_5px_25px_0px_rgba(126,137,155,0.11)] md:max-h-[410px] md:max-w-[304px]',
      )}
    >
      <Skeleton className='!block aspect-[1.11] h-auto w-full' />
      <Container className='flex flex-col justify-between px-5 pb-6'>
        <Container>
          <Skeleton width={249} height={14} />
          <Skeleton width={152} height={14} />
        </Container>
        <Container center className='justify-between'>
          <Skeleton width={70} height={14} />
          <Skeleton width={70} height={14} />
        </Container>
      </Container>
    </Card>
  );
}
