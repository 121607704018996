import Link from 'next/link';

import 'react-loading-skeleton/dist/skeleton.css';

import Card, { CardContent, CardMedia } from '@/components/@base/card';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/search/result/item/type';

import useCommonModalStore from '@/store/common-modal-store';

export default function Item({ icon, title, slug ,demoId}: Props) {
  const { setShow } = useCommonModalStore();

  const handleCloseModal = () => {
    setShow(false);
  };
  const link=demoId? `/web/${slug}/demo?demo_id=${demoId}`:`/web/${slug}`;
  return (
    <Link href={link} onClick={handleCloseModal} prefetch={false}>
      <Card
        noShadow
        center
        className='flex-row items-center justify-start gap-3 rounded-none border-0 border-b border-[#F4F5F6] py-[14px]'
      >
        <CardMedia src={icon.publicLink} alt={icon.title} width={35} height={35} />

        <CardContent className='p-0'>
          <Text className='text-[#5B5C60]'> {title}</Text>
        </CardContent>
      </Card>
    </Link>
  );
}
