import Base from '@/components/@base/@helpers/types';

export type Props = {
  id?: string;
  type: BANNER_TYPE;
  image?: string;
  title?: string;
  subTitle?: string;
  buttonText?: string;
  link: string;
  isExternalLink?: boolean;
  width: number;
  height: number;
  target?: string;
  rel?: string;
} & Base;

export enum BANNER_TYPE {
  IMAGE = 'image',
  TEXT = 'text',
}
