import { CgEye } from 'react-icons/cg';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Props from '@/components/shared/components/preview-button/type';

import ICON_SIZE from '@/constant/icon-size-color';
import localization from '@/constant/localization';

export default function PreviewButton({ slug, variant, className, hasIcon, demoId }: Props) {
  const previewLink = demoId ? `/web/${slug}/demo?demo_id=${demoId}` : `/web/${slug}/demo`;
  return (
    <a
      href={previewLink}
      target='_blank'
      rel='noopener noreferrer'
      className={cn('h-[42px] flex-1', className)}
    >
      <Button
        size={SIZE_ENUM.SM}
        variant={variant}
        Icon={hasIcon && <CgEye size={ICON_SIZE.sm} />}
        className='h-full w-full p-0 text-[13px] font-bold text-[#7E899B] transition duration-300 hover:bg-[#878F9B] hover:text-white'
      >
        {localization.productPreview}
      </Button>
    </a>
  );
}
