import ImageModel from '@/models/image.model';
import { RateModel } from '@/models/product.model';

export default interface BundleItemModel {
  id: string;
  icon: ImageModel;
  slug: string;
  value: number;
  title: string;
  price: number;
  type: string;
  rate: RateModel;
}

export enum BundleTypes {
  PLUGIN = 'plugin',
  THEME = 'theme',
}
