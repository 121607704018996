import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Heading, Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import Props from '@/components/shared/components/icon-text-combo/type';

import { DATA_CY_ICON_TEXT_COMBO } from '@/constant/cypress';

export default function IconTextCombo({
  wrapperClassName,
  Icon,
  title,
  textType = TEXT_TYPE.SPAN,
  headingType,
  textClassName,
  textSize = SIZE_ENUM.SM,
  onClick,
}: Props) {
  return (
    <Container
      center
      className={cn('flex w-full justify-start gap-3 pb-2', wrapperClassName)}
      data-cy={DATA_CY_ICON_TEXT_COMBO}
      onClick={onClick}
    >
      {Icon}
      {headingType ? (
        <Heading type={headingType} className={textClassName}>
          {title}
        </Heading>
      ) : (
        <Text size={textSize} type={textType} className={textClassName}>
          {title}
        </Text>
      )}
    </Container>
  );
}
